.nav__root{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90px;
    width: 100%;
    justify-content: center;
    align-self: center;
    z-index: -10;
    /* box-shadow: 0 5px 5px -5px rgb(233, 233, 233); */
  }

.tool1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tool2{
    display: none;
}

.tool1__part1{
    margin-left: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.tool1__part2{
    justify-content: center;
    align-self: center;
    flex: 2;
}

.tool1__part3{
    flex: 3;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
}

.tool1__part4{
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-self: center;
}

.icon__images{
    margin-right: 10px;
}

.icon__images:hover,
.icon__images:focus{
    filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
}

.burgerButton {
    cursor: pointer;
    display: none;
    align-content: center;
    align-self: center;
}

.burgerButton__bar{
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #333333;
    cursor: pointer;
}

#click{
    display: none;
} 

.nav__logo{
    color: #333333;
    font-size: 35px;
    font-weight: 600;
    margin-left: 50px;
    margin-right: 50px;
}

.nav__logo__image{
    width: 100px;
    height: 100px;
}

.nav__logo__container{
    margin-top: 40px;
    margin-left: 10px;
}

.linkList__ul{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
}

.linkList > ul > li{
    margin: 0 5px;
}

.linkList__item{
    color: #333333;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    padding: 8px 15px;
    border-radius: 5px;
    letter-spacing: 1px;
    transition: all 0.3s ease;
}

.linkList__item.active,
.linkList__item:hover{
  color: white;
  background: #333333;
}


@media (max-width: 990px) {
    .nav__root{
        height: 90px;
    }

    .tool1__part2{
        display: none;
    }

    .tool1__part4{
        display: none;
    }

    .tool2{
        display: block;
        margin-top: 15px;
        padding-left: 25px;
        padding-right: 25px;
        z-index: 1;
    }

    .burgerButton{
        display: block;
    }

    #click:checked ~ .tool1__part1 .burgerButton .burgerButton__bar:nth-child(2) {
        opacity: 0;
    }

    #click:checked ~ .tool1__part1 .burgerButton .burgerButton__bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    #click:checked ~ .tool1__part1 .burgerButton .burgerButton__bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }

    .linkList__ul {
        position: fixed;
        top: 90px;
        left: -100%;
        background: #333;
        z-index: 1;
        height: 100vh;
        width: 100%;
        text-align: left;
        display: block;
        transition: all 0.3s ease;
    }

    #click:checked ~ .tool1__part3 .linkList .linkList__ul {
        left: 0;
    }

    .linkList__li {
        width: 100%;
        margin: 40px 0;
    }

    .linkList__item {
        width: 100%;
        margin-left: 20px;
        margin-top: 25px;
        display: block;
        font-size: 20px;
        transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        color: white;
    }

    #click:checked ~ .tool1__part3 .linkList .linkList__ul .linkList__li .linkList__item {
        left: 0;
    }

   .linkList__item.active,
   .linkList__item:hover{
        background: none;
        color: cyan;
    }

    .nav__logo__image {
        width: 70px;
        height: 70px;
    }

    .nav__logo__container{
        margin-top: 10px;
        margin-left: 40px;
    }
}