.products{
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.products__filters{
    vertical-align: super;
    min-width: 200px;
    flex: 1;
    margin-left: 20px;
}

@media only screen and (max-width: 700px){
    .products__filters {
        display: none;
    }

    .products__grid{
        margin-right: 0px;
        flex: 1;
        justify-content: center;
    }
}