.header__container{
    max-width: 1300px;
    margin: auto;
    padding-left: 55px;
    padding-right: 55px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
}

.header__col_2{
    flex-basis: 50%;
    min-width: 300px;
}

.header__col_2 img{
    max-width: 100%;
    padding: 50px 0;
}
.header__col_2 h1{
    font-size: 50px;
    line-height: 60px;
    margin: 25px 0;
}

.btn{
    display: inline-block;
    background: #ff523b;
    color: #fff;
    padding: 8px 30px;
    margin: 30px 0;
    border-radius: 30px;
    transition: background 0.5s;
}
.btn:hover{
    background: #563434;
}




@media only screen and (max-width: 800px){
    
    .header__container{
      text-align: center;
    }
    
    .header__col_2, .col-3{
        
        flex-basis: 100%;
    }
}