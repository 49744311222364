.product__list__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.products__grid{
    background: #f9f9f9;
    display: flex;
    direction: row;
    flex: 5;
    margin-right: 80px;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.products__loader__container{
    margin: 0;
    opacity: 1;
    position: absolute;
    top: 30%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 700px){

    .products__grid{
        background: #f9f9f9;
        margin-right: 0px;
        flex: 1;
        justify-content: center;
    }
}

.product-display-section {
    background: #f9f9f9;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    overflow: auto;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }