* {
  margin: 0;
  padding: 0;
}

a{
  text-decoration: none;
}

body{
  font-family: 'Poppins', sans-serif;
}

@font-face {
  font-family: "NunitoSans";
  src: local("NunitoSans"), url("./fonts/NunitoSans/NunitoSans-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "NunitoSans";
  src: local("NunitoSans"), url("./fonts/NunitoSans/NunitoSans-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "NunitoSans";
  src: local("NunitoSans"), url("./fonts/NunitoSans/NunitoSans-Light.ttf") format("truetype");
  font-weight: 300;
}