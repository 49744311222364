.search-container {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding: 6px 0px;
    border-radius: 20px;
    border: 2px solid #eaeaea;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: 98%;
  }
  
  .search-input {
    flex: 1;
    background-color: transparent;
    border: none;
    outline: none;
    padding-left: 20px;
    font-weight: 600;
    font-size: 16px;
    color: rgb(37, 37, 37);
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  }

  .search-input::placeholder {
    color: #9b9b9b;
  }
  
  .search-btn {
    background-color: #1565C0;
    border: none;
    border-radius: 50%;
    color: white;
    font-size: 20px;
    height: 40px;
    width: 40px;
    cursor: pointer;
    outline: none;
    margin-right: 10px;
  }
  
  .search-btn:hover {
    background-color: #5B6C7A;
  }