.product__card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  width: 300px;
  height: 320px;
  margin: 20px;
  padding: 20px;
  text-align: center;
}

.product__card:hover,
.product__card:focus {
  border-radius: 8px;
  box-shadow:
    0 5px 10px #cecdcd,
    0 10px 10px #cecdcd;
}

.product__card img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 200px;
  width: 100%;
  object-fit: contain;
}

.product__card h3 {
  padding: 20px;
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  color: rgb(37, 37, 37);
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;

}

.product__info {
  align-items: flex-start;
  text-align: left;
}

.product__name {
  margin-top: 22px;
  color: #303030;
  font-weight: 600;
  font-size: 20px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
}

.product__brand {
  margin-top: 12px;
  color: #037F8C;
  font-weight: 500;
  font-size: 12px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
}

.product__size {
  color: #F28241;
  font-weight: 500;
  font-size: 12px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
}