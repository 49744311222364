.categories{
    margin: 70px 0;
}

.small__container{
    max-width: 1080px;
    margin: auto;
    padding-left: 25px;
    padding-right: 25px;
}

.small__container2{
    max-width: 1300px;
    align-items: center;
    margin: auto;
    padding-left: 25px;
    padding-right: 25px;
}

.small__container3{
    max-width: 1300px;
    align-items: center;
    margin: auto;
    padding-left: 25px;
    padding-right: 25px;
}

.row{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;  
}

.col-3{
    flex-basis: 30%;
    min-width: 250px;
    margin-bottom: 30px;
}
.col-3 img{
    width: 100%;
}

.title{
    text-align: center;
    margin: 0px auto 80px;
    position: relative;
    line-height: 60px;
    color: #555;
}
.title::after{
    content: '';
    background: #ff523b;
    width: 80px;
    height: 5px;
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.products__grid{
    background: #f9f9f9;
    display: flex;
    direction: row;
    flex: 5;
    margin-right: 80px;
    flex-wrap: wrap;
    justify-content: flex-start;
}

@media only screen and (max-width: 700px){

    .products__grid{
        margin-right: 0px;
        flex: 1;
        justify-content: center;
    }
}


