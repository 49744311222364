.footer__root{
    width: 100%;
    height: 100%;
    padding-top: 70px;
    padding-bottom: 30px;
    background-color: #333333;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
}

.footer__logo{
    color: #ffffff;
    font-size: 35px;
    font-weight: 600;
    margin-left: 50px;
    margin-right: 50px;
}

.footer__section1{
    margin-right: 50px;
    margin-left: 50px;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.footer__section2{
    display: flex;
    margin-bottom: 70px;
    margin-right: 70px;
    margin-left: 15px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.footer__section3{
    display: flex;
    margin-bottom: 30px;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}

.footer__text{
    font-size: 14px;
    font-weight: 800;
    margin-bottom: 20px;
    color: #a6a6a6;
    font-family: "NunitoSans";
}

.footer__imageSocial{
    margin-right: 30px;
    height: 40px;
    width: 40px;
    align-self: center;
}

.home__poductTitle {
    font-family: 'Roboto', sans-serif;
    text-align: center;
    font-size: 28px;
    color: #333333;
}


.home__productText {
    font-family: 'Roboto', sans-serif;
    text-align: center;
    font-size: 16px;
    color: #616161;
}